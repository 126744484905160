import React, { FC, useMemo } from 'react'
import { HeaderWithContent } from '@/components/layouts/HeaderWithContent'
import { useQuery } from 'react-apollo'
import { GetReminder, GetReminderVariables, GetReminder_reminder } from '@/queries/_gen_/GetReminder'
import { GetReminderQuery } from '@/queries/reminders.queries'
import { QueryContainer } from '@/components/QueryContainer'
import { reminderTypeNames, ReminderType, ReminderStatus } from '@/models/reminders'
import { ReminderStatusTag } from './components/ReminderStatusTag'
import { RoutedTabPane, RoutedTabs } from '@/components/RoutedTabs'
import { ReminderGeneralTab } from './components/tabs/ReminderGeneralTab'
import { Link, useParams } from 'react-router-dom'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { ReminderEventsTab } from './components/tabs/ReminderEventsTab'
import { withSpan } from '@/components/contexts/TraceContext'

const ReminderDetailsInnerView: FC<{ reminder: GetReminder_reminder }> = ({ reminder }) => {
  const header = (
    <>
      <Link to={usePathWithParams('/reminders')}>
        <ArrowLeftOutlined title='Back' />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <ReminderStatusTag $reminderStatus={reminder.status as ReminderStatus} />
      &nbsp;-&nbsp;
      {reminderTypeNames[reminder.type as ReminderType]}
      &nbsp;-&nbsp;
      {reminder.debtor.name}
    </>
  )

  const panes: RoutedTabPane[] = useMemo(
    () => [
      {
        name: 'General',
        path: '',
        render: () => <ReminderGeneralTab reminder={reminder} />,
        flexContainer: true
      },
      {
        name: 'History',
        path: '/history',
        render: () => <ReminderEventsTab reminder={reminder} />
      }
    ],
    [reminder]
  )

  return (
    <HeaderWithContent header={header} fitToContainer={true}>
      <RoutedTabs fitToContainer={true} pathRoot={`/reminders/${reminder.id}`} panes={panes} type='card' animated={false} />
    </HeaderWithContent>
  )
}

export const ReminderDetailsView = withSpan<Record<string, never>>(
  'ReminderDetailsView',
  props => ({
    'reminder.id': props.reminderId
  }),
  (): JSX.Element => {
    const reminderId = Number(useParams().id!)
    const queryResult = useQuery<GetReminder, GetReminderVariables>(GetReminderQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        reminder_id: reminderId
      }
    })

    return (
      <QueryContainer<GetReminder> queryResult={queryResult} container={true}>
        {reminder => (reminder.reminder ? <ReminderDetailsInnerView reminder={reminder.reminder} /> : <p>Reminder not found.</p>)}
      </QueryContainer>
    )
  }
)
