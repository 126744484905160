import React, { FC, useCallback, useMemo } from 'react'
import './tinymce-imports'
import { Editor, IAllProps as EditorProps } from '@tinymce/tinymce-react'
import { setupVariableAutocompleter } from './tinymce-autocompleter'
import { uploadFile } from '@/utils/fetch'
import { Folder } from 'common/models/files'
import { useCurrentClient } from '@/hooks/useCurrentClient'
import { MARGINS_PLUGIN_ID, getContentWithMargins } from './plugins/MarginsPlugin'
import { INVOICE_TABLE_PLUGIN_ID } from './plugins/InvoiceTablePlugin'
import { REMINDER_PDF_PREVIEW_PLUGIN_ID } from './plugins/ReminderPDFPreviewPlugin'
import { TinyMCEChangeEvent } from 'tinymce'
import { TINYMCE_FONT_FORMATS, DEFAULT_FONT_FAMILY, FONTS_IMPORT_URL } from 'common/document_fonts'
import { INVOICE_PDF_PREVIEW_PLUGIN_ID } from '@/components/tinymce/plugins/InvoicePDFPreviewPlugin'
import { InvoiceTemplateVariables, invoiceTemplateVariables } from 'common/models/invoice-template'
import { reminderTemplateVariables, TemplateVariables } from 'common/models/template'

export const TinymceAllReminderPlugins = `${MARGINS_PLUGIN_ID} fullpage ${INVOICE_TABLE_PLUGIN_ID} ${REMINDER_PDF_PREVIEW_PLUGIN_ID} searchreplace autolink directionality visualblocks visualchars fullscreen image link table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help code`
export const TinymceAllInvoicePlugins = `${MARGINS_PLUGIN_ID} fullpage  ${INVOICE_PDF_PREVIEW_PLUGIN_ID} searchreplace autolink directionality visualblocks visualchars fullscreen image link table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help code`
export const TinymceFullReminderToolar = `formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment | ${INVOICE_TABLE_PLUGIN_ID} | ${REMINDER_PDF_PREVIEW_PLUGIN_ID} | ${MARGINS_PLUGIN_ID} | code`
export const TinymceFullInvoiceToolar = `formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment | ${INVOICE_PDF_PREVIEW_PLUGIN_ID} | ${MARGINS_PLUGIN_ID} | code`

export const DEFAULT_CONTENT_CSS = 'https://cdn.jsdelivr.net/npm/tinymce@5.0.11/skins/content/default/content.min.css'
export const DOCUMENT_CONTENT_CSS = ['https://cdn.jsdelivr.net/npm/tinymce@5.0.11/skins/content/document/content.min.css', FONTS_IMPORT_URL]

interface TinymceEditorProps extends EditorProps {
  onChange?: (content: string) => void
  autoCompleteVariables?: Array<keyof InvoiceTemplateVariables> | Array<keyof TemplateVariables>
}

const defaultInit = (clientId: number, variables?: Array<keyof InvoiceTemplateVariables> | Array<keyof TemplateVariables>) => ({
  images_upload_handler(
    blobInfo: { blob(): Blob; filename(): string },
    success: (url: string) => void,
    failure: (msg: string) => void
  ): void {
    uploadFile(Folder.images, clientId, blobInfo.blob(), blobInfo.filename())
      .then(res => success(res.location))
      .catch(e => failure(e.error || e.message || String(e)))
  },
  skin: false,
  convert_urls: false,
  relative_urls: false,
  remove_script_host: false,
  content_css: DEFAULT_CONTENT_CSS,
  setup: (editor: any) => setupVariableAutocompleter(editor, variables)
})

export const TinymceEditor: FC<TinymceEditorProps> = ({ onChange, init, ...props }) => {
  const client = useCurrentClient()

  const onChangeCallback = useCallback(
    (e: TinyMCEChangeEvent) => {
      const content = getContentWithMargins(e.target)
      if (onChange) {
        onChange(content)
      }
    },
    [onChange]
  )

  const newInit = useMemo(
    () => ({
      ...defaultInit(client.id, props.autoCompleteVariables),
      ...(init || {})
    }),
    [init]
  )

  return <Editor {...props} init={newInit} onChange={onChangeCallback} />
}

const documentContentCss = `
  body {
    font-family: ${DEFAULT_FONT_FAMILY};
  }
  img {
    max-width: 100%;
    height: 100%;
  }
`

export const TinymceReminderDocumentEditor: FC<Omit<TinymceEditorProps, 'plugins' | 'toolbar'>> = ({ init, ...props }) => (
  <TinymceEditor
    {...props}
    autoCompleteVariables={reminderTemplateVariables}
    plugins={TinymceAllReminderPlugins}
    toolbar={TinymceFullReminderToolar}
    init={{
      ...init,
      height: 800,
      content_css: DOCUMENT_CONTENT_CSS,
      font_formats: TINYMCE_FONT_FORMATS,
      content_style: documentContentCss
    }}
  />
)

export const TinymceInvoiceDocumentEditor: FC<Omit<TinymceEditorProps, 'plugins' | 'toolbar'>> = ({ init, ...props }) => (
  <TinymceEditor
    {...props}
    autoCompleteVariables={invoiceTemplateVariables}
    plugins={TinymceAllInvoicePlugins}
    toolbar={TinymceFullInvoiceToolar}
    init={{
      ...init,
      height: 800,
      content_css: DOCUMENT_CONTENT_CSS,
      font_formats: TINYMCE_FONT_FORMATS,
      content_style: documentContentCss
    }}
  />
)

const TinymceAutocompleteOnly: FC<Omit<TinymceEditorProps, 'plugins' | 'toolbar'>> = ({ init, ...props }) => (
  <TinymceEditor
    {...props}
    plugins=''
    toolbar=''
    init={{
      ...init,
      menubar: false,
      toolbar: false,
      statusbar: false,
      height: 100,
      content_css: DEFAULT_CONTENT_CSS
    }}
  />
)

export const RemindersTinymceAutocompleteOnly: FC<Omit<TinymceEditorProps, 'plugins' | 'toolbar'>> = props => (
  <TinymceAutocompleteOnly autoCompleteVariables={reminderTemplateVariables} {...props} />
)

export const TinymceSimple: FC<Omit<TinymceEditorProps, 'plugins' | 'toolbar'>> = ({ init, ...props }) => (
  <TinymceEditor
    autoCompleteVariables={reminderTemplateVariables}
    {...props}
    plugins=''
    toolbar=''
    init={{
      ...init,
      height: 400,
      content_css: DEFAULT_CONTENT_CSS,
      menubar: false
    }}
  />
)
