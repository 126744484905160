import { Column } from "./invoice";

export interface TemplateVariables {
  clientName: string;
  clientAddress: string;
  debtorExtra1: string;
  debtorExtra2: string;
  debtorAddress: string;
  myCompanyName: string;
  debtorName: string;
  debtorCompanyCode: string;
  date: string;
  total: string; // same as outstanding for historical reasons
  outstanding: string;
  paid: string;
  fullAmount: string;
  invoiceExtra1: string;
  invoiceExtra2: string;
  invoiceExtra3: string;
  invoiceExtra4: string;
  invoiceExtra5: string;
  invoiceExtra6: string;
  extraDocumentNumber1: string;
  documentNumber: string;
}
export const reminderTemplateVariables: Array<keyof TemplateVariables> = [
  "clientName",
  "clientAddress",
  "debtorExtra1",
  "debtorExtra2",
  "debtorAddress",
  "debtorName",
  "debtorCompanyCode",
  "date",
  "total",
  "paid",
  "fullAmount",
  "outstanding",
  "documentNumber",
  "invoiceExtra1",
  "invoiceExtra2",
  "invoiceExtra3",
  "invoiceExtra4",
  "invoiceExtra5",
  "invoiceExtra6",
  "extraDocumentNumber1",
];

export const TABLE_VAR_PREFIX = "table:";

export type TableConfigRaw = [[string, string][], [string, string]];

export type TableConfig = {
  columns: Array<{ column: Column; label: String }>;
  totalsLabel: String;
};

export enum TemplateType {
  email = "email",
  sms = "sms",
  mail = "mail",
}

export const templateTypeNames: { [key in TemplateType]: string } = {
  [TemplateType.email]: "Email",
  [TemplateType.sms]: "SMS",
  [TemplateType.mail]: "Mail",
};

export const templateTypes = Object.values(TemplateType);

export enum TemplateLanguage {
  lithuanian = "lt",
  english = "en",
  russian = "ru",
  german = "de",
  latvian = "lv",
  polish = "pl",
}

export const templateLanguageNames: { [key in TemplateLanguage]: string } = {
  [TemplateLanguage.lithuanian]: "Lithuanian",
  [TemplateLanguage.english]: "English",
  [TemplateLanguage.russian]: "Russian",
  [TemplateLanguage.german]: "German",
  [TemplateLanguage.latvian]: "Latvian",
  [TemplateLanguage.polish]: "Polish",
};

export const templateLanguageShortNames: { [key in TemplateLanguage]: string } =
  {
    [TemplateLanguage.lithuanian]: "Lit",
    [TemplateLanguage.english]: "Eng",
    [TemplateLanguage.russian]: "Ru",
    [TemplateLanguage.german]: "Ger",
    [TemplateLanguage.latvian]: "Lv",
    [TemplateLanguage.polish]: "Pl",
  };

export const templateLanguages = Object.values(TemplateLanguage);
