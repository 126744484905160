import React, { useState, useCallback, useMemo, useContext, FC } from 'react'
import OrigSider from 'antd/lib/layout/Sider'
import Menu from 'antd/lib/menu'
import {
  PoweroffOutlined,
  RedEnvelopeOutlined,
  HomeOutlined,
  UploadOutlined,
  MailOutlined,
  TeamOutlined,
  ControlOutlined,
  TableOutlined,
  FileTextOutlined,
  GlobalOutlined,
  UserOutlined,
  FilePdfOutlined,
  FileExcelOutlined
} from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import SubMenu from 'antd/lib/menu/SubMenu'
import { CurrentClientContext } from './contexts/CurrentClientContext'
import { useProfile } from '@/hooks/useProfile'
import { fullName, initials } from 'common/models/user'
import { useRole } from '@/hooks/useRole'
import { Input } from 'antd'
import { SearchClients_items } from '@/queries/_gen_/SearchClients'

const Sider = styled(OrigSider)`
  .ant-layout-sider-children {
    overflow-y: auto;
  }
`

const Brand = styled.span`
  color: white;
  font-weight: bold;
  font-size: 150%;
`

const Username = styled.span`
  vertical-align: 0.5em;
`

const COLLAPSED_STORAGE_KEY = '_payup_sidebar_collapsed'

const initialCollapsed = localStorage.getItem(COLLAPSED_STORAGE_KEY) === 'true'

export const Sidebar: FC = () => {
  const [collapsed, setCollapsed] = useState(initialCollapsed)

  const onCollapseChange = useCallback(
    (newCollapsed: boolean) => {
      setCollapsed(newCollapsed)
      localStorage.setItem(COLLAPSED_STORAGE_KEY, String(newCollapsed))
    },
    [setCollapsed]
  )

  const location = useLocation()

  const { client, clients, setClient } = useContext(CurrentClientContext)

  const selectedKeys = useMemo(
    () => [location.pathname, location.pathname.split('/').slice(0, 2).join('/'), `client-${client.id}`],
    [location, client]
  )

  const [clientFilterQuery, setClientFilterQuery] = useState('')

  const selectClient = (c: SearchClients_items) => {
    setClient(c)
    setClientFilterQuery('')
  }

  const activeClients = useMemo(() => clients.filter(client => !client.is_archived), [clients])

  const filteredClients = useMemo(() => {
    const query = clientFilterQuery.trim().toLocaleLowerCase()
    if (query.length && activeClients) {
      return activeClients.filter(client => client.name.toLocaleLowerCase().includes(query))
    }
    return activeClients
  }, [activeClients, clientFilterQuery])

  const profile = useProfile()!

  const { isManager, isAdmin } = useRole()

  return (
    <Sider collapsible={true} onCollapse={onCollapseChange} collapsed={collapsed}>
      <Menu theme='dark' mode='vertical' selectedKeys={selectedKeys}>
        <Menu.Item>
          <Link to='/'>
            <Brand>{collapsed ? 'I' : 'InvoRep'}</Brand>
          </Link>
        </Menu.Item>
        <SubMenu
          title={
            <>
              <Username>{collapsed ? initials(profile) : fullName(profile)}</Username>
            </>
          }
        >
          <Menu.Item key='/me'>
            <Link to='/me'>
              <span>My profile</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='/logout'>
            <Link to='/logout'>
              <PoweroffOutlined />
              <span>Logout</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        {activeClients.length > 1 && (
          <SubMenu title={<span>{collapsed ? client.name[0] : client.name}</span>}>
            <Menu.Item onDestroy={() => console.log('des')}>
              <Input
                autoFocus={true}
                onClick={e => {
                  e.stopPropagation()
                }}
                onKeyUp={e => {
                  if (e.key === 'Enter' && filteredClients.length === 1) {
                    selectClient(filteredClients[0])
                  }
                }}
                onChange={e => setClientFilterQuery(e.target.value)}
                value={clientFilterQuery}
              />
            </Menu.Item>
            {filteredClients.map(c => (
              <Menu.Item onClick={() => selectClient(c)} key={`client-${c.id}`}>
                <span>{c.name}</span>
              </Menu.Item>
            ))}
          </SubMenu>
        )}
        {activeClients.length === 1 && (
          <Menu.Item>
            <span>{collapsed ? client.name[0] : client.name}</span>
          </Menu.Item>
        )}
        <Menu.Item key='/'>
          <Link to='/'>
            <HomeOutlined />
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
        {isManager && (
          <SubMenu
            title={
              <>
                <RedEnvelopeOutlined />
                <span>Invoices</span>
              </>
            }
          >
            <Menu.Item key='/import'>
              <Link to='/import'>
                <UploadOutlined />
                Import invoices
              </Link>
            </Menu.Item>
            <Menu.Item key='/import_payments'>
              <UploadOutlined />
              <Link to='/import_payments'>Import payments</Link>
            </Menu.Item>
            <Menu.Item key='/invoices'>
              <Link to='/invoices?f_is_paid=false'>
                <TableOutlined />
                View invoices
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        {!isManager && (
          <Menu.Item key='/invoices'>
            <Link to='/invoices?f_is_paid=false'>
              <TableOutlined />
              <span>Invoices</span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key='/reminders'>
          <Link to='/reminders?f_status=new'>
            <MailOutlined />
            <span>Reminders</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='/reports'>
          <Link to='/reports'>
            <FileExcelOutlined />
            <span>Reports</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='/debtors'>
          <Link to='/debtors'>
            <TeamOutlined />
            <span>Debtors</span>
          </Link>
        </Menu.Item>
        {isManager && (
          <Menu.Item key='/categories'>
            <Link to='/categories'>
              <ControlOutlined />
              <span>Categories</span>
            </Link>
          </Menu.Item>
        )}
        {isManager && (
          <SubMenu
            title={
              <>
                <FileTextOutlined />
                <span>Templates</span>
              </>
            }
          >
            <Menu.Item key='/templates/reminders'>
              <Link to='/templates/reminders'>
                <MailOutlined />
                Reminder Templates
              </Link>
            </Menu.Item>
            <Menu.Item key='/templates/invoices'>
              <Link to='/templates/invoices'>
                <FilePdfOutlined />
                Invoice Templates
              </Link>
            </Menu.Item>
          </SubMenu>
        )}

        {isAdmin && <Menu.Divider />}
        {isManager && (
          <SubMenu title={collapsed ? 'Adm.' : 'Admin'}>
            <Menu.Item key='/clients'>
              <Link to='/clients'>
                <GlobalOutlined />
                <span>Clients</span>
              </Link>
            </Menu.Item>
            {isAdmin && (
              <Menu.Item key='/users'>
                <Link to='/users'>
                  <UserOutlined />
                  <span>Users</span>
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
      </Menu>
    </Sider>
  )
}
