import { UserRole } from 'common/models/user'
import { useCurrentClient } from './useCurrentClient'
import { useProfile } from './useProfile'

export interface UserRoleValue {
  role?: UserRole
  isManager: boolean
  isGuest: boolean
  isAdmin: boolean
}

export function useRole(): UserRoleValue {
  const client = useCurrentClient()
  const profile = useProfile()
  const role = profile?.is_admin
    ? UserRole.manager
    : (profile?.user_roles.find(role => role.client_id === client.id)?.role as UserRole | undefined)
  return {
    role,
    isManager: role === UserRole.manager,
    isGuest: role === UserRole.guest,
    isAdmin: !!profile?.is_admin
  }
}
