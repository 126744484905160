import React, { useCallback, useEffect } from 'react'
import AntdForm from 'antd/lib/form'
import { UserWithPassword, User, UserWithPasswordAndId } from '@/models/user'
import ApolloClient from 'apollo-client'
import * as yup from 'yup'
import { userUniquenessValidator } from '../userValidators'
import { userRoles, UserRole, fullName } from 'common/models/user'
import { FC, useMemo } from 'react'
import { useApolloClient } from 'react-apollo'
import { Formik, FieldArray } from 'formik'
import Container from '@/components/layouts/Container'
import { Form } from '@/components/form/Form'
import { FormikField } from '@/components/form/FormikField'
import { FormikSwitchField } from '@/components/form/FormikSwitchField'
import { FormSubmitButton } from '@/components/form/FormSubmitButton'
import { FormMutation } from '@/components/form/FormMutation'
import { InsertUser, InsertUserVariables } from '@/queries/_gen_/InsertUser'
import { InsertUserMutation, UpdateUserMutation } from '@/queries/users.queries'
import { UpdateUser, UpdateUserVariables } from '@/queries/_gen_/UpdateUser'
import { RemoveItemButton } from '@/components/form/RemoveItemButton'
import * as R from 'ramda'
import { UserRoleSelect } from '@/components/form/UserRoleSelect'
import { ClientSelect } from '@/components/form/ClientSelect'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

interface UserFormProps {
  user: UserWithPassword
  onSubmit: (value: UserWithPassword) => void
  loading: boolean
  btnLabel: string
}

const emptyUser: UserWithPassword = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  is_active: true,
  is_admin: false,
  user_roles: []
}

const RolesTable = styled.table`
  th:nth-child(1) {
    min-width: 20em;
  }

  th:nth-child(2) {
    min-width: 10em;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  margin-bottom: 3px;
`

export const passwordValidator = yup.string().min(8, 'Minimum 8 characters.')

const validationSchema = (apollo: ApolloClient<any>, userId?: string) =>
  yup.object().shape({
    first_name: yup.string().required('Required!'),
    last_name: yup.string().required('Required!'),
    email: yup
      .string()
      .email('Not a valid email.')
      .required('Required!')
      .test('email_unique', 'Another user with this email already exists.', userUniquenessValidator(apollo, 'email', userId)),
    password: userId ? passwordValidator : passwordValidator.required('Required!'),
    user_roles: yup.array().of(
      yup.object().shape({
        role: yup.string().oneOf(userRoles).required('Required!'),
        client_id: yup.number().required('Required!')
      })
    )
  })

const UserForm: FC<UserFormProps> = props => {
  const { user, loading, onSubmit, btnLabel } = props
  const apollo = useApolloClient()
  const vSchema = useMemo(() => validationSchema(apollo, user.id), [apollo, user.id])

  return (
    <Container $fitToContainer={true} $scrollable={true}>
      <Formik<UserWithPassword> initialValues={user} onSubmit={onSubmit} validationSchema={vSchema} enableReinitialize={true}>
        {({ dirty, values, setFieldValue }) => (
          <Form className='ant-form ant-form-vertical'>
            <FormikField name='first_name' label='First name' />
            <FormikField name='last_name' label='Last name' />
            <FormikField name='email' label='Email' />
            <FormikField
              name='password'
              label='Password'
              type='password'
              disableAutocomplete={true}
              description='Leave blank to keep current password.'
            />
            <FormikSwitchField name='is_active' label='Is active' />
            <FormikSwitchField name='is_admin' label='Is admin' />
            <FieldArray name='user_roles'>
              {arrayHelpers => (
                <>
                  <RolesTable>
                    <thead>
                      <tr>
                        <th>Client</th>
                        <th>Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.user_roles.map(({ role, client_id }, idx) => (
                        <tr key={idx}>
                          <td>
                            <FormikField name={`user_roles.${idx}.client_id`}>
                              {({ field }) => (
                                <ClientSelect value={client_id} onChange={client_id => setFieldValue(field.name, client_id)} />
                              )}
                            </FormikField>
                          </td>
                          <td>
                            <FormikField name={`user_roles.${idx}.role`}>
                              {({ field }) => (
                                <UserRoleSelect value={role as UserRole} onChange={role => setFieldValue(field.name, role)} />
                              )}
                            </FormikField>
                          </td>
                          <td>
                            <AntdForm.Item>
                              <RemoveItemButton title='remove role' onClick={() => arrayHelpers.remove(idx)} />
                            </AntdForm.Item>
                          </td>
                        </tr>
                      ))}
                      {!values.user_roles.length && (
                        <tr>
                          <td colSpan={3}>
                            <p>This user has no roles in any project. Click '+' button below to add one.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </RolesTable>
                  <Button
                    icon={<PlusOutlined />}
                    title='Add role'
                    onClick={() => arrayHelpers.insert(values.user_roles.length, { role: UserRole.guest, client_id: null })}
                  />
                </>
              )}
            </FieldArray>
            <br />
            <FormSubmitButton htmlType='submit' type='primary' disabled={!dirty || loading} loading={loading}>
              {btnLabel}
            </FormSubmitButton>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

interface CreateUserFormProps {
  onSaved: (user: User) => void
}

export const CreateUserForm: FC<CreateUserFormProps> = ({ onSaved }) => {
  const buildVariables = useCallback((values: UserWithPassword): InsertUserVariables => {
    const { user_roles, password, ...rest } = values
    return {
      payload: {
        ...rest,
        ...(password ? { password } : {}),
        user_roles: {
          data: user_roles
        }
      }
    }
  }, [])

  return (
    <FormMutation<UserWithPassword, InsertUser, InsertUserVariables>
      buildVariables={buildVariables}
      successMessage={data => (data.result && `User ${fullName(data.result)} created!`) || '???'}
      mutation={InsertUserMutation}
      refetchQueries={['SearchUsers']}
    >
      {({ onSubmit, result: { loading, data } }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (data && data.result && data.result) {
            onSaved(data.result)
          }
        }, [data])
        return <UserForm user={emptyUser} onSubmit={onSubmit} loading={loading} btnLabel='Create user' />
      }}
    </FormMutation>
  )
}

interface UpdateUserFormProps {
  user: UserWithPasswordAndId
}

export const UpdateUserForm: FC<UpdateUserFormProps> = ({ user }) => {
  const buildVariables = useCallback(
    (values: UserWithPassword): UpdateUserVariables => {
      const { user_roles, password, ...rest } = R.omit(['id'], values)

      return {
        payload: {
          ...rest,
          ...(password ? { password } : {})
        },
        id: user.id,
        roles: user_roles.map(role => ({
          ...R.omit(['__typename', 'client'], role),
          user_id: user.id
        }))
      }
    },
    [user]
  )

  return (
    <FormMutation<UserWithPassword, UpdateUser, UpdateUserVariables>
      buildVariables={buildVariables}
      successMessage={data => (data.result ? `User ${fullName(data.result)} updated.` : '???')}
      mutation={UpdateUserMutation}
    >
      {({ onSubmit, result: { loading } }) => <UserForm user={user} onSubmit={onSubmit} loading={loading} btnLabel='Update user' />}
    </FormMutation>
  )
}
