import React, { FC } from 'react'
import Container from '@/components/layouts/Container'
import { GetReminder_reminder } from '@/queries/_gen_/GetReminder'
import { ReminderActionButtons } from '../ReminderActionButtons'
import { UpdateDebtorDrawer } from '@/views/debtors/components/UpdateDebtorDrawer'
import { usePathWithParams } from '@/hooks/usePathWithParams'
import { Row, Col } from 'antd/lib/grid'
import { ReminderDetails } from '../ReminderDetails'
import { EventsHistory } from '@/components/history/EventsHistory'
import styled from 'styled-components'
import { ReminderInvoicesTable } from '../ReminderInvoicesTable'
import { Routes, useLocation, useParams } from 'react-router'
import { Route, useNavigate } from 'react-router-dom'
import { UpdateInvoiceDrawer } from '@/views/invoices/components/UpdateInvoiceDrawer'

const FlexRow = styled(Row)`
  flex: 1;
  flex-flow: row !important;
  overflow: hidden;
`

const FlexColHalf = styled(Col)`
  flex: 1 0 50% !important;
`

const FlexCol = styled(Col)`
  flex: 1 0 100% !important;
`
const InvoicesCol = styled(FlexCol)`
  padding-top: 5px;
`

const GeneralCol = styled(Col)`
  display: flex;
  flex-direction: column;
`

const DetailsContainer = styled.div`
  height: 100%;
  overflow: auto;
  flex: 1;
`

export interface ReminderGeneralTabProps {
  reminder: GetReminder_reminder
}

export const ReminderGeneralTab: FC<ReminderGeneralTabProps> = ({ reminder }) => {
  const baseUrl = usePathWithParams(`/reminders/${reminder.id}`)
  const nav = useNavigate()
  const location = useLocation()

  return (
    <Container $fitToContainer={true}>
      <FlexRow>
        <GeneralCol>
          <ReminderActionButtons reminder={reminder} />
          <DetailsContainer>
            <ReminderDetails reminder={reminder} />
          </DetailsContainer>
        </GeneralCol>
        <FlexColHalf>
          <Container $scrollable={true} $fitToContainer={true}>
            <EventsHistory commentsOnly={true} debtorLevel={true} reminderId={reminder.id} debtorId={reminder.debtor.id} />
          </Container>
        </FlexColHalf>
      </FlexRow>
      <FlexRow>
        <InvoicesCol>
          <ReminderInvoicesTable key={String(reminder.id)} reminder={reminder} staticHeight={80} />
        </InvoicesCol>
      </FlexRow>
      <UpdateDebtorDrawer
        path={`/reminders/${reminder.id}/debtor`}
        onClose={() => nav(baseUrl)}
        debtorId={location.pathname.startsWith(`/reminders/${reminder.id}/debtor`) ? reminder.debtor.id : undefined}
      />
      <Routes>
        <Route path='/invoices/:id/*' element={<RoutedUpdateInvoiceDrawer onClose={() => nav(baseUrl)} reminderId={reminder.id} />} />
      </Routes>
    </Container>
  )
}

const RoutedUpdateInvoiceDrawer: FC<{ reminderId: number; onClose: () => void }> = ({ reminderId, onClose }) => {
  const invoiceId = Number(useParams().id!)
  const path = `/reminders/${reminderId}/invoices/${invoiceId}`
  return <UpdateInvoiceDrawer onClose={onClose} path={path} invoiceId={invoiceId} />
}
