export interface InvoiceTemplateVariables {
  clientName: string;
  clientAddress: string;
  invoiceExtraInfo1: string;
  invoiceExtraInfo2: string;
  invoiceExtraInfo3: string;
  invoiceExtraInfo4: string;
  invoiceExtraInfo5: string;
  invoiceExtraInfo6: string;
  extraDocumentNumber1: string;
  debtorExtraInfo1: string;
  debtorExtraInfo2: string;
  myCompanyName: string;
  myCompanyCode: string;
  myCompanyVatCode: string;
  debtorName: string;
  debtorCompanyCode: string;
  debtorAddress: string;
  debtorCity: string;
  debtorZipCode: string;
  debtorCountry: string;
  invoiceNumber: string;
  amountWithVat: string;
  amountWOVat: string;
  documentDate: string;
  dueDate: string;
  lineName: string;
  lineQuantity: string;
  linePrice: string;
  lineProductId: string;
  daysLate: string;
  date1: string;
  date2: string;
  time1: string;
}

export const invoiceTemplateVariables: Array<keyof InvoiceTemplateVariables> = [
  "clientName",
  "clientAddress",
  "invoiceExtraInfo1",
  "invoiceExtraInfo2",
  "invoiceExtraInfo3",
  "invoiceExtraInfo4",
  "invoiceExtraInfo5",
  "invoiceExtraInfo6",
  "extraDocumentNumber1",
  "debtorExtraInfo1",
  "debtorExtraInfo2",
  "myCompanyName",
  "myCompanyCode",
  "myCompanyVatCode",
  "debtorName",
  "debtorCompanyCode",
  "debtorAddress",
  "debtorCity",
  "debtorZipCode",
  "debtorCountry",
  "invoiceNumber",
  "amountWithVat",
  "amountWOVat",
  "documentDate",
  "dueDate",
  "lineName",
  "lineQuantity",
  "linePrice",
  "lineProductId",
  "daysLate",
  "date1",
  "date2",
  "time1",
];
