import gql from 'graphql-tag'

export const UserFieldsFragment = gql`
  fragment FUserFields on user {
    id
    first_name
    email
    is_active
    is_admin
    last_name
    user_roles {
      role
      client_id
      client {
        name
        id
      }
    }
  }
`

export const SearchUsersQuery = gql`
  query SearchUsers($limit: Int!, $offset: Int!, $where: user_bool_exp!, $order_by: [user_order_by!]) {
    items: user(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      ...FUserFields
    }
    total: user_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }

  ${UserFieldsFragment}
`
export const CountUsersQuery = gql`
  query CountUsers($where: user_bool_exp) {
    result: user_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const InsertUserMutation = gql`
  mutation InsertUser($payload: user_insert_input!) {
    result: insert_user_one(object: $payload) {
      ...FUserFields
    }
  }

  ${UserFieldsFragment}
`

export const UpdateUserMutation = gql`
  mutation UpdateUser($payload: user_set_input!, $roles: [user_role_insert_input!]!, $id: uuid!) {
    result: update_user_by_pk(pk_columns: { id: $id }, _set: $payload) {
      ...FUserFields
    }
    delete_user_role(where: { user_id: { _eq: $id } }) {
      affected_rows
    }
    insert_user_role(objects: $roles) {
      affected_rows
    }
  }

  ${UserFieldsFragment}
`

export const UpdateProfileMutation = gql`
  mutation UpdateProfile($payload: user_set_input!, $id: uuid!) {
    result: update_user_by_pk(pk_columns: { id: $id }, _set: $payload) {
      ...FUserFields
    }
  }

  ${UserFieldsFragment}
`

export const GetUserQuery = gql`
  query GetUser($id: uuid!) {
    user: user_by_pk(id: $id) {
      ...FUserFields
    }
  }
  ${UserFieldsFragment}
`

export const GetMeQuery = gql`
  query GetMe {
    me {
      me_user {
        ...FUserFields
      }
    }
  }
  ${UserFieldsFragment}
`
