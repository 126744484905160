export enum Column {
  documentNumber = "document_number",
  invoiceDate = "document_date",
  dueDate = "due_date",
  amountWithVat = "amount_with_vat",
  amountWithoutVat = "amount_wo_vat",
  amountOutstanding = "amount_outstanding",
  vat = "vat",
  currency = "currency",
  extra1 = "extra_1",
  extra2 = "extra_2",
  extra3 = "extra_3",
  extra4 = "extra_4",
  extra5 = "extra_5",
  extra6 = "extra_6",
  extraDocumentNumber1 = "extra_document_number_1",
  debtorExtra1 = "debtor_extra_1",
  debtorExtra2 = "debtor_extra_2",
  daysLate = "days_late",
  amountPaid = "amount_paid",
  fullAmount = "full_amount",
  date1 = "date_1",
  date2 = "date_2",
  time1 = "time_1",
}

export const monetaryColumns = [
  Column.amountOutstanding,
  Column.amountWithVat,
  Column.amountWithoutVat,
  Column.vat,
  Column.amountPaid,
  Column.fullAmount,
];

export const dateColumns = [
  Column.invoiceDate,
  Column.dueDate,
  Column.date1,
  Column.date2,
];

export const columnNames: { [key in Column]: string } = {
  [Column.documentNumber]: "Document no.",
  [Column.invoiceDate]: "Document date",
  [Column.dueDate]: "Due date",
  [Column.amountOutstanding]: "Outstanding",
  [Column.amountWithVat]: "Amount w. VAT",
  [Column.amountWithoutVat]: "Amount w/o VAT",
  [Column.vat]: "VAT",
  [Column.currency]: "Currency",
  [Column.extra1]: "Extra 1",
  [Column.extra2]: "Extra 2",
  [Column.extra3]: "Extra 3",
  [Column.extra4]: "Extra 4",
  [Column.extra5]: "Extra 5",
  [Column.extra6]: "Extra 6",
  [Column.extraDocumentNumber1]: "Extra document no. 1",
  [Column.debtorExtra1]: "Debtor extra 1",
  [Column.debtorExtra2]: "Debtor extra 2",
  [Column.daysLate]: "Days late",
  [Column.amountPaid]: "Amount paid",
  [Column.fullAmount]: "Full amount",
  [Column.date1]: "Date 1",
  [Column.date2]: "Date 2",
  [Column.time1]: "Time 1",
};

export const selectableColumns: Column[] = [
  Column.documentNumber,
  Column.invoiceDate,
  Column.dueDate,
  Column.amountOutstanding,
  Column.amountWithVat,
  Column.amountWithoutVat,
  Column.amountPaid,
  Column.fullAmount,
  Column.vat,
  Column.currency,
  Column.extra1,
  Column.extra2,
  Column.extra3,
  Column.extra4,
  Column.extra5,
  Column.extra6,
  Column.extraDocumentNumber1,
  Column.debtorExtra1,
  Column.debtorExtra2,
  Column.daysLate,
  Column.date1,
  Column.date2,
  Column.time1,
];
