import React, { FC } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Layout from '@/components/layouts/RootLayout'
import { Login } from '@/views/Login'
import { Logout } from '@/views/Logout'
import DebtorsView from '@/views/debtors/DebtorsView'
import DashboardView from '@/views/DashboardView'
import { CategoriesView } from './categories/CategoriesView'
import { InvoicesTemplatesView, RemindersTemplatesView } from './templates/TemplatesView'
import { ClientsView } from './clients/ClientsView'
import { CurrentClientProvider } from '@/components/contexts/CurrentClientContext'
import { ImportView } from './import/ImportView'
import { InvoicesView } from './invoices/InvoicesView'
import { RemindersView } from './reminders/RemindersView'
import { initApolloClient } from '@/apollo'
import { ApolloProvider } from 'react-apollo'
import { UsersView } from './users/UsersView'
import { ProfileProvider } from '@/components/contexts/ProfileContext'
import { ResetPassword } from './ResetPassword'
import { ResetPasswordComplete } from './ResetPasswordComplete'
import { ProfileView } from './ProfileView'
import { useProfile } from '@/hooks/useProfile'
import { useRole } from '@/hooks/useRole'
import { PaymentImportView } from './payment_import/PaymentImportView'
import { EventBusProvider } from '@/components/contexts/EventBusContext'
import { ReportsView } from './reports/ReportsView'

const apolloClient = initApolloClient()

// split into 3 components because hooks, heh

const AuthenticatedRoutes: FC = () => {
  const { isManager, isAdmin } = useRole()
  console.log('remder authenticated routes')
  return (
    <Routes>
      <Route path='/' element={<DashboardView />} />
      <Route path='/me' element={<ProfileView />} />
      {isManager && <Route path='/import/*' element={<ImportView />} />}
      {isManager && <Route path='/import_payments/*' element={<PaymentImportView />} />}
      {isManager && <Route path='/clients/*' element={<ClientsView />} />}`
      <Route path='/debtors/*' element={<DebtorsView />} />
      <Route path='/invoices/*' element={<InvoicesView />} />
      {isManager && <Route path='/categories/*' element={<CategoriesView />} />}
      {isManager && <Route path='/templates/reminders/*' element={<RemindersTemplatesView />} />}
      {isManager && <Route path='/templates/invoices/*' element={<InvoicesTemplatesView />} />}
      <Route path='/reminders/*' element={<RemindersView />} />
      <Route path='/reports/*' element={<ReportsView />} />
      {isAdmin && <Route path='/users/*' element={<UsersView />} />}
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  )
}

const AppRoutes: FC = () => {
  const profile = useProfile()

  const location = useLocation()
  console.log('App routes', profile)
  return (
    <Routes>
      <Route path='/logout' element={<Logout />} />
      <Route path='/login' element={<Login />} />
      <Route path='/password_reset' element={<ResetPassword />} />
      <Route path='/password_reset/:token' element={<ResetPasswordComplete />} />
      <Route
        path='*'
        element={
          profile ? (
            <CurrentClientProvider>
              <Layout>
                <AuthenticatedRoutes />
              </Layout>
            </CurrentClientProvider>
          ) : (
            <Navigate to='/login' state={{ referrer: location.pathname + location.search }} />
          )
        }
      />
    </Routes>
  )
}

export const App: FC = () => (
  <EventBusProvider>
    <ApolloProvider client={apolloClient}>
      <ProfileProvider>
        <AppRoutes />
      </ProfileProvider>
    </ApolloProvider>
  </EventBusProvider>
)
