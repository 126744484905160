import { Plan, InvoiceFields, ReminderFields } from '@/models/import/plan'
import { useCallback, useState } from 'react'
import { uploadFile } from '@/utils/fetch'
import { useApolloClient } from 'react-apollo'
import {
  InsertImportFileMutation,
  ImportInsertInvoicesMutation,
  ImportInsertRemindersMutation,
  ImportInsertDebtorContactsMutation,
  ImportInsertDebtorsMutation,
  ImportSetClientNextInvoiceNumberMutation,
  ImportUpdateDebtorMutation,
  UpsertImportFileColumnConfigMutation
} from '@/queries/import.queries'
import { InsertImportFile, InsertImportFileVariables } from '@/queries/_gen_/InsertImportFile'
import { RawWorkbook } from '@/models/import/xls'
import { useCurrentClient } from '../useCurrentClient'
import { debtor_insert_input, invoice_insert_input, reminder_insert_input } from '@/queries/_gen_global'
import * as R from 'ramda'
import { ReminderStatus } from '@/models/reminders'
import { GraphQLError } from 'graphql'
import { FetchResult } from 'apollo-link'
import { ClientWithId } from '@/models/client'
import { useCurrentUserId } from '../useCurrentUsername'
import combineQuery, { CombinedQueryBuilder } from 'graphql-combine-query'
import { ImportCancelRemindersMutation, ImportMarkInvoicesAsPaidMutation } from '@/queries/import.queries'
import { ImportCancelRemindersVariables, ImportCancelReminders } from '@/queries/_gen_/ImportCancelReminders'
import { ImportMarkInvoicesAsPaidVariables } from '@/queries/_gen_/ImportMarkInvoicesAsPaid'
import { ImportMarkInvoicesAsPaid } from '@/queries/_gen_/ImportMarkInvoicesAsPaid'
import { Folder } from 'common/models/files'
import { logger } from '@/logger'
import { ImportInsertDebtorsVariables, ImportInsertDebtors } from '@/queries/_gen_/ImportInsertDebtors'
import { ImportInsertInvoices, ImportInsertInvoicesVariables } from '@/queries/_gen_/ImportInsertInvoices'
import { ImportInsertReminders, ImportInsertRemindersVariables } from '@/queries/_gen_/ImportInsertReminders'
import { ImportInsertDebtorContacts, ImportInsertDebtorContactsVariables } from '@/queries/_gen_/ImportInsertDebtorContacts'
import { ImportUpdateClientSeriesNumber, ImportUpdateClientSeriesNumberVariables } from '@/queries/_gen_/ImportUpdateClientSeriesNumber'
import { toBackendDate } from '@/utils/date'
import { ImportUpdateDebtorVariables } from '@/queries/_gen_/ImportUpdateDebtor'
import { UpsertImportFileColumnConfig, UpsertImportFileColumnConfigVariables } from '@/queries/_gen_/UpsertImportFileColumnConfig'

type ImportFn = (plan: Plan, file: File, raw: RawWorkbook) => void

export class ImportError extends Error {
  constructor(
    message: string,
    // eslint-disable-next-line prettier/prettier
    private graphqlErrors?: GraphQLError[]
  ) {
    super(message)
  }

  get message(): string {
    return [this.message, (this.graphqlErrors || []).map(err => err.message)].join(' ')
  }

  static fromResult(message: string, result: FetchResult<unknown>): ImportError {
    return new ImportError(message, (result.errors || []).slice())
  }
}

function castDate(dt: Date | null | string | undefined): string | null | undefined {
  if (typeof dt === 'string') {
    return dt
  }
  return dt ? toBackendDate(dt) : dt
}

export function createMutation(plan: Plan, client: ClientWithId, userId: string, importFileId: number): CombinedQueryBuilder {
  const toInvoiceInput = (invFields: InvoiceFields): invoice_insert_input => {
    const { invoice_line_items, ...restInvFields } = invFields
    return {
      ...restInvFields,
      date_1: castDate(restInvFields.date_1),
      date_2: castDate(restInvFields.date_2),
      due_date: castDate(restInvFields.due_date),
      document_date: castDate(restInvFields.document_date),
      import_file_invoices: {
        data: [
          {
            import_file_id: importFileId
          }
        ]
      },
      invoice_line_items: {
        data: invoice_line_items
      }
    }
  }

  const toReminderInput = ({
    template,
    reminder_type,
    reminder_period,
    is_suspended,
    period_days_from,
    period_days_to
  }: ReminderFields): reminder_insert_input => ({
    client_id: client.id,
    template_id: template ? template.id : null,
    type: reminder_type,
    import_file_id: importFileId,
    period: reminder_period,
    status: is_suspended ? ReminderStatus.suspended : ReminderStatus.new,
    period_days_from,
    period_days_to
  })

  // insert contacts
  let mut = combineQuery('Import').add<ImportInsertDebtorContacts, ImportInsertDebtorContactsVariables>(
    ImportInsertDebtorContactsMutation,
    {
      insert_contacts: R.flatten(
        plan.update_debtors.map(d => d.new_contacts.map(v => ({ ...v, debtor_id: d.id, import_file_id: importFileId })))
      )
    }
  )

  // cancel old reminders
  if (plan.import_options.generateReminders) {
    mut = mut.add<ImportCancelReminders, ImportCancelRemindersVariables>(ImportCancelRemindersMutation, {
      client_id: client.id,
      user: userId
    })
  }

  // mark old invoices as paid
  if (plan.import_options.markOldInvoicesAsPaid) {
    mut = mut.add<ImportMarkInvoicesAsPaid, ImportMarkInvoicesAsPaidVariables>(ImportMarkInvoicesAsPaidMutation, {
      existing_invoice_ids: R.flatten(plan.update_debtors.map(debtor => debtor.update_invoices.map(i => i.id))),
      client_id_2: client.id
    })
  }

  // insert new debtors
  mut = mut.add<ImportInsertDebtors, ImportInsertDebtorsVariables>(ImportInsertDebtorsMutation, {
    insert_debtors: plan.new_debtors.map(
      ({ contacts, invoices, reminders, ...debtorFields }): debtor_insert_input => ({
        ...debtorFields,
        client_id: client.id,
        import_file_id: importFileId,
        invoices: {
          data: invoices.map(toInvoiceInput)
        },
        reminders: {
          data: reminders.map(toReminderInput)
        },
        debtor_contacts: {
          data: contacts.map(c => ({ ...c, import_file_id: importFileId }))
        }
      })
    )
  })

  // insert invoices for existing debtors
  if (plan.import_options.importInvoices) {
    mut = mut.add<ImportInsertInvoices, ImportInsertInvoicesVariables>(ImportInsertInvoicesMutation, {
      insert_invoices: R.flatten(
        plan.update_debtors.map(debtor =>
          [...debtor.new_invoices, ...debtor.update_invoices].map(invFields => ({
            ...toInvoiceInput(invFields),
            debtor_id: debtor.id
          }))
        )
      )
    })
  }

  // insert reminders
  if (plan.import_options.generateReminders) {
    mut = mut.add<ImportInsertReminders, ImportInsertRemindersVariables>(ImportInsertRemindersMutation, {
      insert_reminders: R.flatten(
        plan.update_debtors.map(debtor =>
          debtor.new_reminders.map(reminderFields => ({
            ...toReminderInput(reminderFields),
            debtor_id: debtor.id
          }))
        )
      )
    })
  }

  // update client series number if needed
  if (plan.next_invoice_number !== client.next_invoice_number) {
    mut = mut.add<ImportUpdateClientSeriesNumber, ImportUpdateClientSeriesNumberVariables>(ImportSetClientNextInvoiceNumberMutation, {
      client_id_3: client.id,
      next_invoice_number: plan.next_invoice_number
    })
  }

  // update debtors if needed
  const toBeUpdatedDebtors = plan.update_debtors.filter(deb => Object.keys(deb.update_fields).length > 0)
  if (toBeUpdatedDebtors.length) {
    mut = mut.addN<ImportUpdateDebtorVariables>(
      ImportUpdateDebtorMutation,
      toBeUpdatedDebtors.map(deb => ({
        update_debtor_debtor_id: deb.id,
        update_debtor_payload: deb.update_fields
      }))
    )
  }

  // update column types
  if (plan.update_column_types) {
    mut = mut.add<UpsertImportFileColumnConfig, UpsertImportFileColumnConfigVariables>(UpsertImportFileColumnConfigMutation, {
      config: JSON.stringify(plan.update_column_types),
      cc_client_id: client.id,
      column_count: plan.update_column_types.length
    })
  }

  return mut
}

export function useImport(): [ImportFn, Promise<{ importFileId: number }> | null] {
  const [promise, setPromise] = useState<Promise<{ importFileId: number }> | null>(null)

  const apolloClient = useApolloClient()

  const client = useCurrentClient()
  const user = useCurrentUserId()

  const importfn: ImportFn = useCallback(
    (plan: Plan, file: File, raw: RawWorkbook) => {
      // 1. upload the import file
      logger.info(`uploading import file [${file.name}]...`)
      const prom: Promise<{ importFileId: number }> = uploadFile(Folder.importfiles, client.id, file, file.name)
        // 2. create import entity in db
        .then(uploadResult => {
          logger.info('import file uploaded, inserting import to db...')
          return apolloClient.mutate<InsertImportFile, InsertImportFileVariables>({
            mutation: InsertImportFileMutation,
            variables: {
              bucket_filename: uploadResult.filename,
              orig_filename: file.name,
              import_options: plan.import_options,
              client_id: client.id,
              raw_data: raw,
              import_type: 'INVOICES'
            }
          })
        })

        // 3. create debtors, invoices, contacts & reminders
        .then(impFileRes => {
          const data = impFileRes.data
          if (data && data.insert_import_file && data.insert_import_file.returning.length) {
            logger.info('import file inserted, inserting debtors, contacts, reminders & invoices...')
            const result = data.insert_import_file.returning[0]
            const importFileId = result.id
            const { document, variables } = createMutation(plan, client, user, importFileId)
            return apolloClient.mutate({ mutation: document, variables }).then(() => importFileId)
          } else {
            logger.info(`failed to import file :( [${file.name}], errors=[${JSON.stringify(impFileRes.errors)}]`)
            throw ImportError.fromResult('Failed to save import file.', impFileRes)
          }
        })
        .then(importFileId => ({ importFileId }))
        .catch(e => {
          if (e instanceof ImportError) {
            throw e
          }
          logger.error('Unexpected failure while importing', e)
          throw new ImportError(e.message, [])
        })
      setPromise(prom)
    },
    [client, user, apolloClient]
  )

  return [importfn, promise]
}
