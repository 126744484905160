import gql from 'graphql-tag'

export const CategoryStepFieldsFragment = gql`
  fragment FCategoryStepFields on category_step {
    days_from
    days_to
    id
    template_id
    category_id
    reminder_type
  }
`

export const CategoryFieldsFragment = gql`
  fragment FCategoryFields on category {
    id
    name
    client_id
    from_email
    from_sms
    invoice_due_days
    category_steps {
      ...FCategoryStepFields
    }
    invoice_template_id
  }
  ${CategoryStepFieldsFragment}
`

export const CategoryOptionFieldsFragment = gql`
  fragment FCategoryOptionFields on category {
    id
    name
  }
`

export const SearchCategoriesQuery = gql`
  query SearchCategories($limit: Int!, $offset: Int!, $where: category_bool_exp!, $order_by: [category_order_by!]) {
    items: category(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...FCategoryFields
    }
    total: category_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${CategoryFieldsFragment}
`

export const GetCategoryOptionsQuery = gql`
  query GetCategoryOptions($where: category_bool_exp) {
    result: category(where: $where, order_by: { name: asc }) {
      ...FCategoryOptionFields
    }
  }
  ${CategoryOptionFieldsFragment}
`

export const InsertCategoryMutaton = gql`
  mutation InsertCategory($payload: category_insert_input!) {
    result: insert_category(objects: [$payload]) {
      returning {
        ...FCategoryFields
      }
    }
  }
  ${CategoryFieldsFragment}
`

export const UpdateCategoryMutation = gql`
  mutation UpdateCategory($category_id: Int!, $steps: [category_step_insert_input!]!, $category: category_set_input!, $keep_ids: [Int!]!) {
    delete_category_step(where: { id: { _nin: $keep_ids }, category_id: { _eq: $category_id } }) {
      affected_rows
    }
    insert_category_step(
      objects: $steps
      on_conflict: { constraint: category_step_pkey, update_columns: [template_id, days_to, days_from, reminder_type] }
    ) {
      affected_rows
    }
    result: update_category(where: { id: { _eq: $category_id } }, _set: $category) {
      returning {
        ...FCategoryFields
      }
    }
  }
  ${CategoryFieldsFragment}
`

export const GetCategoryQuery = gql`
  query GetCategory($id: Int!) {
    category: category_by_pk(id: $id) {
      ...FCategoryFields
    }
  }
  ${CategoryFieldsFragment}
`
