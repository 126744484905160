export interface SheetColumn {
  label: string
  type: ColumnType
}

export type Cell = [string, string[]] // value, errors

export interface Sheet {
  name: string
  columns: SheetColumn[]
  rows: string[][] // row:column:value
  errors: string[][][]
}

export interface CurrentWorkbook {
  format: SheetFormat
  selectedSheetName: string
  sheets: { [key: string]: Sheet }
}

export interface RawSheet {
  name: string
  rows: unknown[][]
}

export interface RawWorkbook {
  sheets: RawSheet[]
}

export enum SheetFormat {
  // eslint-disable-next-line prettier/prettier
  simple = 'simple',
  simpleNoHeader = 'simple_no_header',
  silva = 'silva',
  bd = 'bd',
  sgb = 'sgb',
  uprent = 'uprent',
  custom = 'custom' // previous use config loaded from db
}
export interface SheetConfig {
  format: SheetFormat
  columnTypes?: ColumnType[]
}

export const sheetFormatsWithoutContactsImport = [SheetFormat.sgb]

export const SheetFormatNames: { [key in SheetFormat]: string } = {
  [SheetFormat.simple]: 'Basic',
  [SheetFormat.simpleNoHeader]: 'Basic (no header)',
  [SheetFormat.silva]: 'SILVA',
  [SheetFormat.bd]: 'BD',
  [SheetFormat.sgb]: 'Scandinavian Good Business',
  [SheetFormat.uprent]: 'Uprent',
  [SheetFormat.custom]: 'Custom'
}

export const sheetFormats = Object.values(SheetFormat)

export enum ColumnType {
  skip = 'skip',
  documentNumber = 'document_number',
  companyCode = 'debtor_code',
  companyName = 'debtor_name',
  documentDate = 'document_date',
  dueDate = 'due_date',
  amountWithoutVat = 'amount_without_vat',
  amountWithVat = 'amount_with_vat',
  vat = 'vat',
  amountPaid = 'amount_paid',
  amountOutstanding = 'amount_outstanding',
  email = 'email',
  email2 = 'email2',
  email3 = 'email3',
  phoneNumber = 'phone_number',
  phoneNumber2 = 'phone_number2',
  phoneNumber3 = 'phone_number3',
  address = 'address',
  currency = 'currency',
  customerCodeInClientSystem = 'customer_code_in_client_system',
  linePrice = 'line_price',
  lineProductName = 'line_product_name',
  lineQuantity = 'line_quantity',
  lineProductId = 'line_product_id',
  firstName = 'first_name',
  lastName = 'last_name',
  country = 'country',
  city = 'city',
  postCode = 'post_code',
  invoiceExtra1 = 'extra_1',
  invoiceExtra2 = 'extra_2',
  invoiceExtra3 = 'extra_3',
  invoiceExtra4 = 'extra_4',
  invoiceExtra5 = 'extra_5',
  invoiceExtra6 = 'extra_6',
  extraDocumentNumber1 = 'extra_document_number_1',
  date1 = 'date_1',
  date2 = 'date_2',
  time1 = 'time_1',
  debtorExtra1 = 'debtor_extra_1',
  debtorExtra2 = 'debtor_extra_2',
  debtorCategory = 'debtor_category',
  fullAmount = 'full_amount'
}

export const columnTypes = Object.values(ColumnType)

const defaultInvoiceImportRequiredColumns = [
  ColumnType.companyName,
  ColumnType.documentNumber,
  ColumnType.amountOutstanding,
  ColumnType.dueDate
]

export const columnsRequiredForInvoiceImport: { [key in SheetFormat]: ColumnType[] } = {
  [SheetFormat.simpleNoHeader]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.simple]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.silva]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.bd]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.uprent]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.custom]: defaultInvoiceImportRequiredColumns,
  [SheetFormat.sgb]: [
    ColumnType.firstName,
    ColumnType.lastName,
    ColumnType.lineProductId,
    ColumnType.lineProductName,
    ColumnType.linePrice,
    ColumnType.documentDate,
    ColumnType.address,
    ColumnType.city,
    ColumnType.country,
    ColumnType.postCode
  ]
}

export const columnTypeNames: { [key in ColumnType]: string } = {
  [ColumnType.skip]: 'Skip',
  [ColumnType.documentNumber]: 'Doc. number',
  [ColumnType.companyCode]: 'Comp. code',
  [ColumnType.companyName]: 'Comp. name',
  [ColumnType.documentDate]: 'Doc. date',
  [ColumnType.dueDate]: 'Due date',
  [ColumnType.amountWithoutVat]: 'Amt. w/o VAT',
  [ColumnType.amountWithVat]: 'Amt. with VAT',
  [ColumnType.vat]: 'VAT',
  [ColumnType.amountPaid]: 'Amt. paid',
  [ColumnType.amountOutstanding]: 'Amt. outstanding',
  [ColumnType.email]: 'Email',
  [ColumnType.email2]: 'Email 2',
  [ColumnType.email3]: 'Email 3',
  [ColumnType.phoneNumber]: 'Phone number',
  [ColumnType.phoneNumber2]: 'Phone number 2',
  [ColumnType.phoneNumber3]: 'Phone number 3',
  [ColumnType.address]: 'Address',
  [ColumnType.currency]: 'Currency',
  [ColumnType.customerCodeInClientSystem]: 'Code in client sys.',
  [ColumnType.linePrice]: 'Line price',
  [ColumnType.lineProductName]: 'Line product name',
  [ColumnType.lineQuantity]: 'Line quantity',
  [ColumnType.lineProductId]: 'Line product id',
  [ColumnType.firstName]: 'First name',
  [ColumnType.lastName]: 'Last name',
  [ColumnType.country]: 'Country',
  [ColumnType.city]: 'City',
  [ColumnType.postCode]: 'Post code',
  [ColumnType.invoiceExtra1]: 'Invoice extra 1',
  [ColumnType.invoiceExtra2]: 'Invoice extra 2',
  [ColumnType.invoiceExtra3]: 'Invoice extra 3',
  [ColumnType.invoiceExtra4]: 'Invoice extra 4',
  [ColumnType.invoiceExtra5]: 'Invoice extra 5',
  [ColumnType.invoiceExtra6]: 'Invoice extra 6',
  [ColumnType.extraDocumentNumber1]: 'Extra doc. number 1',
  [ColumnType.debtorExtra1]: 'Debtor extra 1',
  [ColumnType.debtorExtra2]: 'Debtor extra 2',
  [ColumnType.debtorCategory]: 'Debtor category',
  [ColumnType.fullAmount]: 'Full amount',
  [ColumnType.date1]: 'Date 1',
  [ColumnType.date2]: 'Date 2',
  [ColumnType.time1]: 'Time 1'
}

export const contactColumnTypes = [ColumnType.address, ColumnType.phoneNumber, ColumnType.email]
