import gql from 'graphql-tag'

export const ReminderFieldsFragment = gql`
  fragment FReminderFields on reminder {
    debtor {
      id
      company_code
      vat_code
      reference_code
      customer_code_in_client_system
      name
      suspend_reminders_until
      are_reminders_suspended
      debtor_contacts(where: { is_enabled: { _eq: true } }) {
        id
        type
        value
        description
      }
    }
    id
    status
    type
    template {
      language
      name
      id
      type
    }
    created_at
    updated_at
    completed_at
    import_file {
      id
      orig_filename
    }
    cancelled_at
    cancelled_by_user {
      id
      first_name
      last_name
    }
    completed_at
    completed_by_user {
      id
      first_name
      last_name
    }
    period
    period_days_from
    period_days_to
  }
`

export const SearchRemindersQuery = gql`
  query SearchReminders($offset: Int!, $limit: Int!, $where: reminder_bool_exp!, $order_by: [reminder_order_by!]) {
    items: reminder(offset: $offset, limit: $limit, where: $where, order_by: $order_by) {
      ...FReminderFields
    }
    total: reminder_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${ReminderFieldsFragment}
`

export const GetReminderQuery = gql`
  query GetReminder($reminder_id: Int!) {
    reminder: reminder_by_pk(id: $reminder_id) {
      ...FReminderFields
    }
  }
  ${ReminderFieldsFragment}
`

export const GetRemindersQuery = gql`
  query GetReminders($reminder_ids: [Int!]!) {
    reminder(where: { id: { _in: $reminder_ids } }) {
      ...FReminderFields
    }
  }
  ${ReminderFieldsFragment}
`

export const GetReminderStatsQueryName = 'GetReminderStats'

export const GetReminderStatsQuery = gql`
  query GetReminderStats($where: reminder_bool_exp!) {
    total_new_sms: reminder_aggregate(
      where: {
        _and: [
          $where
          { type: { _eq: "sms" }, status: { _eq: "new" } }
          { _not: { debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: "phone" } } } } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_new_emails: reminder_aggregate(
      where: {
        _and: [
          $where
          { type: { _eq: "email" }, status: { _eq: "new" } }
          { _not: { debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: "email" } } } } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    missing_contacts: reminder_aggregate(
      where: {
        _and: [
          $where
          { status: { _eq: "new" } }
          {
            _or: [
              { type: { _eq: "email" }, debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: "email" } } } } }
              {
                _or: [{ type: { _eq: "sms" } }, { type: { _eq: "phone" } }]
                debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: "phone" } } } }
              }
              { type: { _eq: "mail" }, debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: "address" } } } } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const CancelReminderMutation = gql`
  mutation CancelReminder($reminder_id: Int!, $user: uuid!) {
    __typename
    update_reminder(where: { id: { _eq: $reminder_id } }, _set: { status: "cancelled", cancelled_at: "now()", cancelled_by: $user }) {
      returning {
        ...FReminderFields
      }
    }
  }
  ${ReminderFieldsFragment}
`

export const MarkReminderAsSentMutation = gql`
  mutation MarkReminderAsSent($reminder_id: Int!, $user: uuid!) {
    __typename
    update_reminder(
      where: { id: { _eq: $reminder_id } }
      _set: { status: "sent", completed_at: "now()", completed_by: $user, cancelled_at: null, cancelled_by: null }
    ) {
      returning {
        ...FReminderFields
      }
    }
  }
  ${ReminderFieldsFragment}
`

export const SendRemindersMutation = gql`
  mutation SendReminders($client_id: Int!, $reminder_requests: [reminder_send_request_insert_input!]!, $reminder_ids: [Int!]!) {
    insert_reminders_send_request(objects: { client_id: $client_id, reminder_send_requests: { data: $reminder_requests } }) {
      returning {
        id
        reminder_send_requests {
          id
        }
      }
    }
    update_reminder(
      where: { id: { _in: $reminder_ids } }
      _set: { status: "queued", completed_at: null, completed_by: null, cancelled_at: null, cancelled_by: null }
    ) {
      returning {
        ...FReminderFields
      }
    }
  }

  ${ReminderFieldsFragment}
`

export const GetReminderIdsQuery = gql`
  query GetReminderIds($where: reminder_bool_exp!, $client_id: Int!) {
    reminder(
      where: {
        _and: [
          $where
          {
            _and: [{ status: { _neq: "queued" } }, { status: { _neq: "suspended" } }]
            debtor: { client_id: { _eq: $client_id } }
            _or: [
              {
                type: { _eq: "sms" }
                _not: { debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: "phone" } } } } }
              }
              {
                type: { _eq: "email" }
                _not: { debtor: { _not: { debtor_contacts: { is_enabled: { _eq: true }, type: { _eq: "email" } } } } }
              }
            ]
          }
        ]
      }
    ) {
      id
    }
  }
`

export const RemindersSendRequestsSubscription = gql`
  subscription RemindersSendRequestSubscription($client_id: Int!, $created_since: timestamptz!) {
    reminders_send_request(
      where: { client_id: { _eq: $client_id }, _or: [{ completed_at: { _is_null: true } }, { created_at: { _gte: $created_since } }] }
      order_by: { created_at: desc }
    ) {
      id
      client_id
      reminder_send_requests {
        id
        status
        reminder {
          ...FReminderFields
        }
      }
      creator {
        id
        first_name
        last_name
      }
      created_at
      completed_at
    }
  }
  ${ReminderFieldsFragment}
`
