import gql from 'graphql-tag'

export const InvoiceFieldsFragment = gql`
  fragment FInvoiceFields on invoice {
    id
    amount_outstanding
    amount_paid
    amount_with_vat
    amount_wo_vat
    currency
    document_date
    document_number
    due_date
    is_paid
    vat
    suspend_reminders_until
    is_sending_suspended
    extra_1
    extra_2
    extra_3
    extra_4
    extra_5
    extra_6
    extra_document_number_1
    date_1
    date_2
    time_1
    full_amount
    debtor {
      id
      name
      company_code
    }
    import_file_invoices {
      import_file_id
      import_file {
        orig_filename
      }
    }
    events_aggregate {
      aggregate {
        count
      }
    }
    invoice_attachments {
      id
      orig_filename
      filename
      folder
      file_location
      client_id
    }
    is_late
  }
`

export const SearchInvoicesQuery = gql`
  query SearchInvoices($where: invoice_bool_exp!, $order_by: [invoice_order_by!], $limit: Int!, $offset: Int!) {
    items: invoice(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...FInvoiceFields
    }
    total: invoice_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${InvoiceFieldsFragment}
`

export const GetInvoiceQuery = gql`
  query GetInvoice($id: Int!) {
    invoice: invoice_by_pk(id: $id) {
      ...FInvoiceFields
    }
  }
  ${InvoiceFieldsFragment}
`

export const UpdateInvoiceMutation = gql`
  mutation UpdateInvoice($id: Int!, $payload: invoice_set_input!) {
    result: update_invoice(_set: $payload, where: { id: { _eq: $id } }) {
      returning {
        ...FInvoiceFields
      }
    }
  }

  ${InvoiceFieldsFragment}
`

export const GetDebtorInvoicesQuery = gql`
  query GetDebtorInvoices($debtor_id: Int!) {
    invoices: invoice(where: { debtor_id: { _eq: $debtor_id }, is_paid: { _eq: false }, amount_outstanding: { _gt: "0" } }) {
      ...FInvoiceFields
    }
  }
  ${InvoiceFieldsFragment}
`
